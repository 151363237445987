import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../core/services/user.service';
import { Router } from '@angular/router';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { CodeInputComponent } from 'angular-code-input';
@Component({
  selector: 'app-confirm-phone',
  templateUrl: './confirm-phone.component.html',
  styleUrls: ['./confirm-phone.component.scss']
})
export class ConfirmPhoneComponent implements OnInit {
  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  countryCode!: number;
  mobileNumber!: string;
  invalidCode!: boolean;
  step = 'init';
  // step = 'send-code';
  showTooManyAttempts = false;
  faTimes = faTimes;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService) { }

  mobileForm = this.formBuilder.group({
    mobilePhoneNumber: ['', { validators: [Validators.required], updateOn: 'change' }],
    mobilePhonePrefix: ['', { validators: [Validators.required], updateOn: 'change' }],
  });

  mobileValidationForm = this.formBuilder.group({
    code: ['', { validators: [Validators.required], updateOn: 'change' }]
  });

  ngOnInit(): void {
    this.mobileForm.disable();
    this.userService.getUserInfo().subscribe((user) => {
      this.mobileForm.patchValue({
        mobilePhoneNumber: user.phoneNumber,
        mobilePhonePrefix: user.phoneCountryCode.toString()
      });
    });
  }
  submitForm(): void {
    const mobilePhonePrefix = parseInt(this.mobileForm.value.mobilePhonePrefix || '');
    const mobilePhoneNumber = this.mobileForm.value.mobilePhoneNumber || '';
    this.userService.sendPhoneConfirmationCode(mobilePhonePrefix, mobilePhoneNumber).subscribe((res) => {
      this.step = 'send-code';
    }, (err) => {
      this.showTooManyAttempts = true;
    });
  }
  finalise(): void {
    this.userService.updateUserInfo();
  }
  onOTPCodeCompleted(otpCode: any): void {
    this.mobileValidationForm.patchValue({
      code: otpCode
    });
  }
  submitCode(): void {
    const code = this.mobileValidationForm.value.code?.replace('9999', '99999');
    if (code) {
      this.userService.confirmPhone(code).subscribe((res) => {
        // this.step = 'completed';
        localStorage.setItem('showPhoneNotification', 'true');
        this.userService.updateUserInfo();
      }, (err) => {
        this.invalidCode = true;
      });
    }
  }
}
