<div style="padding: 1%;margin-top: 1%;">
  <p class="title is-size-4">{{'RESIDENTIALADDRESS' | translate}}</p>
  <form [formGroup]="addressForm" (submit)="submitForm()">
    <mat-form-field appearance="outline">
      <mat-label> {{ 'ADDRESS_AUTOCOMPLE_INFO' | translate }} </mat-label>
      <input type="text" appFormInput matInput ngx-gp-autocomplete (onAddressChange)="handleAddressChange($event)"
        id="autocomplete" (paste)="(false)" />
    </mat-form-field>
    <div *ngIf="showFields">
      <div class="columns is-multiline pv-0">
        <div class="column pb-0">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.CITY' | translate }} </mat-label>
            <input type="text" appFormInput matInput formControlName="city" />
            <mat-error *ngIf="addressForm.get('city')!.hasError('required')"
              [innerHtml]="'VALIDATIONMESSAGES.CITY_REQUIRED' | translate">
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column pb-0">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.STREETADDRESS' | translate }} </mat-label>
            <input type="text" appFormInput matInput formControlName="streetName" />
            <mat-error *ngIf="addressForm.get('streetName')!.hasError('required')"
              [innerHtml]="'VALIDATIONMESSAGES.STREETADDRESS_REQUIRED' | translate">
            </mat-error>
            <mat-error *ngIf="addressForm.get('streetName')!.hasError('maxlength')"
              [innerHtml]="'VALIDATIONMESSAGES.MAXLENGTH40GENERIC' | translate">
            </mat-error>
            <mat-error *ngIf="addressForm.get('streetName')!.hasError('pattern')"
              [innerHtml]="'VALIDATIONMESSAGES.ADDRESS_PATTERN' | translate">
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="columns is-multiline pv-0">
        <div class="column pb-0">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.STREETNUMBER' | translate }} </mat-label>
            <input type="text" appFormInput matInput formControlName="streetNumber" />
            <mat-error *ngIf="addressForm.get('streetNumber')!.hasError('required')"
              [innerHtml]="'VALIDATIONMESSAGES.STREETNUMBER_REQUIRED' | translate">
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column pb-0">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.POSTALCODE' | translate }} </mat-label>
            <input type="text" appFormInput matInput formControlName="postalCode" />
            <mat-error *ngIf="addressForm.get('postalCode')!.hasError('required')"
              [innerHtml]="'VALIDATIONMESSAGES.POSTALCODE_REQUIRED' | translate">
            </mat-error>
            <mat-error *ngIf="addressForm.get('postalCode')!.hasError('invalidPostalCode')"
              [innerHtml]="'VALIDATIONMESSAGES.PROPERTYPOSTALCODEGENERIC' | translate">
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="columns is-multiline pv-0">
        <div class="column is-one-third pb-0">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.BLOCKNUMBER' | translate }} </mat-label>
            <input type="number" appFormInput matInput formControlName="blockNumber" />
          </mat-form-field>
        </div>
        <div class="column is-one-third pb-0">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.FLOORNUMBER' | translate }} </mat-label>
            <input type="number" appFormInput matInput formControlName="floorNumber" />
          </mat-form-field>
        </div>
        <div class="column is-one-third pb-0">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.DOORNUMBER' | translate }} </mat-label>
            <input type="text" appFormInput matInput formControlName="doorNumber" />
          </mat-form-field>
        </div>
      </div>
      <div class="columns is-multiline pv-0">
        <div class="column pb-0">
          <mat-form-field appearance="outline" *ngIf="addressForm.get('country')?.value !== 'ES'">
            <mat-label> {{ 'PROFILEDATA.STATE' | translate }} </mat-label>
            <input type="text" appFormInput matInput formControlName="state" />
            <mat-error *ngIf="addressForm.get('state')!.hasError('required')"
              [innerHtml]="'VALIDATIONMESSAGES.STATE_REQUIRED' | translate">
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="addressForm.get('country')?.value === 'ES'">
            <mat-label> {{ 'PROFILEDATA.STATE' | translate }} </mat-label>
            <mat-select formControlName="state">
              <mat-option value="ES-VI">Álava/Araba</mat-option>
              <mat-option value="ES-AB">Albacete</mat-option>
              <mat-option value="ES-A">Alicante</mat-option>
              <mat-option value="ES-AL">Almería</mat-option>
              <mat-option value="ES-O">Asturias</mat-option>
              <mat-option value="ES-AV">Ávila</mat-option>
              <mat-option value="ES-BA">Badajoz</mat-option>
              <mat-option value="ES-PM">Baleares</mat-option>
              <mat-option value="ES-B">Barcelona</mat-option>
              <mat-option value="ES-BU">Burgos</mat-option>
              <mat-option value="ES-CC">Cáceres</mat-option>
              <mat-option value="ES-CA">Cádiz</mat-option>
              <mat-option value="ES-S">Cantabria</mat-option>
              <mat-option value="ES-CS">Castellón</mat-option>
              <mat-option value="ES-CE">Ceuta</mat-option>
              <mat-option value="ES-CR">Ciudad Real</mat-option>
              <mat-option value="ES-CO">Córdoba</mat-option>
              <mat-option value="ES-CU">Cuenca</mat-option>
              <mat-option value="ES-GI">Gerona/Girona</mat-option>
              <mat-option value="ES-GR">Granada</mat-option>
              <mat-option value="ES-GU">Guadalajara</mat-option>
              <mat-option value="ES-SS">Guipúzcoa/Gipuzkoa</mat-option>
              <mat-option value="ES-H">Huelva</mat-option>
              <mat-option value="ES-HU">Huesca</mat-option>
              <mat-option value="ES-J">Jaén</mat-option>
              <mat-option value="ES-C">La Coruña/A Coruña</mat-option>
              <mat-option value="ES-LO">La Rioja</mat-option>
              <mat-option value="ES-GC">Las Palmas</mat-option>
              <mat-option value="ES-LE">León</mat-option>
              <mat-option value="ES-L">Lérida/Lleida</mat-option>
              <mat-option value="ES-LU">Lugo</mat-option>
              <mat-option value="ES-M">Madrid</mat-option>
              <mat-option value="ES-MA">Málaga</mat-option>
              <mat-option value="ES-ML">Melilla</mat-option>
              <mat-option value="ES-MU">Murcia</mat-option>
              <mat-option value="ES-NA">Navarra</mat-option>
              <mat-option value="ES-OR">Orense/Ourense</mat-option>
              <mat-option value="ES-P">Palencia</mat-option>
              <mat-option value="ES-PO">Pontevedra</mat-option>
              <mat-option value="ES-SA">Salamanca</mat-option>
              <mat-option value="ES-SG">Segovia</mat-option>
              <mat-option value="ES-SE">Sevilla</mat-option>
              <mat-option value="ES-SO">Soria</mat-option>
              <mat-option value="ES-T">Tarragona</mat-option>
              <mat-option value="ES-TF">Tenerife</mat-option>
              <mat-option value="ES-TE">Teruel</mat-option>
              <mat-option value="ES-TO">Toledo</mat-option>
              <mat-option value="ES-V">Valencia</mat-option>
              <mat-option value="ES-VA">Valladolid</mat-option>
              <mat-option value="ES-BI">Vizcaya/Bizkaia</mat-option>
              <mat-option value="ES-ZA">Zamora</mat-option>
              <mat-option value="ES-Z">Zaragoza</mat-option>
            </mat-select>
            <mat-error *ngIf="addressForm.get('state')!.hasError('required')"
              [innerHtml]="'VALIDATIONMESSAGES.STATE_REQUIRED' | translate">
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column pb-0">
          <mat-select-country class="select-country" appearance="outline" label="{{'PROFILEDATA.COUNTRY' | translate}}"
            placeHolder="{{'PROFILEDATA.SELECT_COUNTRY' | translate}}" [countries]="allowedCountries"
            [value]="selectedCountry" (onCountrySelected)="onCountrySelected($event)">
          </mat-select-country>
        </div>
      </div>
      <form [formGroup]="propertyForm" *ngIf="showPropertyForm">
        <!-- <div class="columns is-multiline">
          <div class="column pt-0 pb-0 is-one-third">
            <mat-form-field appearance="outline">
              <mat-label> {{ 'PROFILEDATA.PROPERTYTYPE' | translate }} </mat-label>
              <mat-select formControlName="propertyType">
                <mat-option value="0">{{ 'HOUSE' | translate }}</mat-option>
                <mat-option value="1">{{ 'APARTMENT' | translate }}</mat-option>
              </mat-select>
              <mat-error *ngIf="propertyForm.get('propertyType')!.hasError('required')"
                [innerHtml]="'VALIDATIONMESSAGES.PROPERTYTYPE_REQUIRED' | translate">
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column pt-0 pb-0 is-one-third">
            <mat-form-field appearance="outline">
              <mat-label> {{ 'PROFILEDATA.GARDEN' | translate }} </mat-label>
              <mat-select formControlName="garden">
                <mat-option value="true">{{ 'YES' | translate }}</mat-option>
                <mat-option value="false">{{ 'NO' | translate }}</mat-option>
              </mat-select>
              <mat-error *ngIf="propertyForm.get('garden')!.hasError('required')"
                [innerHtml]="'VALIDATIONMESSAGES.GARDEN_REQUIRED' | translate">
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column pt-0 pb-0 is-one-third">
            <mat-form-field appearance="outline">
              <mat-label> {{ 'PROFILEDATA.POOL' | translate }} </mat-label>
              <mat-select formControlName="pool">
                <mat-option value="true">{{ 'YES' | translate }}</mat-option>
                <mat-option value="false">{{ 'NO' | translate }}</mat-option>
              </mat-select>
              <mat-error *ngIf="propertyForm.get('pool')!.hasError('required')"
                [innerHtml]="'VALIDATIONMESSAGES.POOL_REQUIRED' | translate">
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column pt-0 is-half">
            <mat-form-field appearance="outline">
              <mat-label> {{ 'PROFILEDATA.BEDROOMS' | translate }} </mat-label>
              <mat-select formControlName="bedrooms">
                <mat-option value="">{{ '' | translate }}</mat-option>
                <mat-option value="1">{{ '1' | translate }}</mat-option>
                <mat-option value="2">{{ '2' | translate }}</mat-option>
                <mat-option value="3">{{ '3' | translate }}</mat-option>
                <mat-option value="4">{{ '4' | translate }}</mat-option>
                <mat-option value="5">{{ '5' | translate }}</mat-option>
                <mat-option value="6">{{ '6' | translate }}</mat-option>
                <mat-option value="7">{{ '7' | translate }}</mat-option>
                <mat-option value="8">{{ '8' | translate }}</mat-option>
                <mat-option value="9">{{ '9' | translate }}</mat-option>
                <mat-option value="10">{{ '10' | translate }}</mat-option>
              </mat-select>
              <mat-error *ngIf="propertyForm.get('bedrooms')!.hasError('required')"
                [innerHtml]="'VALIDATIONMESSAGES.BEDROOMS_REQUIRED' | translate">
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column pt-0 is-half">
            <mat-form-field appearance="outline">
              <mat-label> {{ 'PROFILEDATA.SIZE' | translate }} </mat-label>
              <input type="text" matInput formControlName="size" />
              <mat-error *ngIf="propertyForm.get('size')!.hasError('required')"
                [innerHtml]="'VALIDATIONMESSAGES.SIZE_REQUIRED' | translate">
              </mat-error>
            </mat-form-field>
          </div>
        </div> -->
      </form>
      <button type="submit" [disabled]="!addressForm.valid"
        class="button is-block is-primary is-fullwidth is-medium mt-5">{{(userSettings ? 'SAVE' : 'NEXT') |
        translate}}</button>
      <br />
    </div>
  </form>
</div>
<!-- <div class="modal onboarding-modal" [class.is-active]="showSpanishMessage">
  <div class="modal-background" style="position: fixed;"></div>
  <div class="modal-content">
    <div class="box shadow" style="text-align: center;">
      <p style="font-size: 18px;">{{'PROFILEDATA.USE_AS_SPANISH_ADDRESS' | translate}}</p>
      <br>
      <div class="columns">
        <div class="column is-2 is-offset-3">
          <button class="button is-primary is-fullwidth" (click)="submitPropertyForm(true)">
            <p class="has-text-centered" style="width: 100%;">
              {{'YES' | translate}}
            </p>
          </button>
        </div>
        <div class="column is-2 is is-offset-2">
          <button class="button is-danger is-fullwidth" (click)="submitPropertyForm(false)">
            <p class="has-text-centered" style="width: 100%;">
              {{'NO' | translate}}
            </p>
          </button>
        </div>
      </div>
    </div>
  </div>
</div> -->