import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent {
  @Input() showOffer!: boolean;
  @Input() show6monthsOffer!: boolean;
  @Input() reference: any

  constructor() {
  }

  subscribeYearly() {
    this.reference.yearlyPayment();
  }

  subscribeMonthly() {
    if (this.show6monthsOffer) {
      this.reference.specialOfferPayment();
    } else {
      this.reference.monthlyPayment();
    }
  }
}
