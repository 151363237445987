<div style="padding: 1%;margin-top: 1%;">
  <p class="title is-size-4">{{ 'PASSPORT_DETAILS_TITLE' | translate }}</p>
  <form [formGroup]="entityTypeForm">
    <div class="columns is-vcentered mx-4 mt-2" style="background-color: #f7fafe; border-radius: 1rem">
      <div class="column is-one-quarter">
        <img src="assets/img/security.png" style="max-height: 10rem" alt="security">
      </div>
      <div class="column is-three-quarter">
        <p class="subtitle">
          {{ 'ID_TRUST_HEADER' | translate }}
        </p>
        <p class="main_container_padding">
          {{ 'ID_TRUST_INFO' | translate }}
        </p>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-full-mobile is-half">
        <mat-form-field appearance="outline">
          <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.BIRTHDATE' | translate }}</mat-label>
          <input appFormInput matInput maskedDate [matDatepicker]="birthdatePicker" formControlName="birthdate">
          <mat-datepicker-toggle matSuffix [for]="birthdatePicker"></mat-datepicker-toggle>
          <mat-datepicker #birthdatePicker></mat-datepicker>
          <mat-error *ngIf="entityTypeForm.get('birthdate')!.hasError('required')"
                     [innerHtml]="'VALIDATIONMESSAGES.BIRTHDATEREQUIRED' | translate">
          </mat-error>
          <mat-error *ngIf="entityTypeForm.get('birthdate')!.hasError('invalidAge')"
                     [innerHtml]="'VALIDATIONMESSAGES.INVALIDAGE' | translate">
          </mat-error>
          <mat-error *ngIf="entityTypeForm.get('birthdate')!.hasError('invalidDateFormat')"
                     [innerHtml]="'VALIDATIONMESSAGES.INVALIDDATEFORMAT' | translate">
          </mat-error>
        </mat-form-field>
      </div>
      <div class="column is-full-mobile is-half">
        <mat-form-field appearance="outline">
          <mat-label> {{ 'USER.REGISTRATION.FIELDS_NAMES.GENDER' | translate }}</mat-label>
          <mat-select formControlName="gender">
            <mat-option value="M">{{ 'USER.REGISTRATION.FIELDS_NAMES.MALE' | translate }}</mat-option>
            <mat-option value="F">{{ 'USER.REGISTRATION.FIELDS_NAMES.FEMALE' | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="entityTypeForm.get('gender')!.hasError('required')"
                     [innerHtml]="'VALIDATIONMESSAGES.GENDERREQUIRED' | translate">
          </mat-error>
        </mat-form-field>
      </div>
      <div class="column is-full-mobile is-half">
        <mat-select-country class="select-country" appearance="outline" label="{{'PROFILEDATA.NATIONALITY' | translate}}"
                            placeHolder="{{'PROFILEDATA.SELECT_COUNTRY' | translate}}"
                            [countries]="allowedCountries" [value]="defaultNationality"
                            (onCountrySelected)="onNationalityChange($event)">
        </mat-select-country>
      </div>
      <div class="column is-full-mobile is-half">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'DOCUMENT_TYPE' | translate }}</mat-label>
          <mat-select formControlName="entityType">
            <mat-option *ngIf="allowDNI" value="DNI">DNI</mat-option>
            <mat-option *ngIf="allowID" value="ID">{{ 'ID_TYPES.ID'|translate }}</mat-option>
            <mat-option *ngIf="allowPassport" value="PASSPORT_NUMBER">{{ 'ID_TYPES.PASSPORT'|translate }}</mat-option>
            <mat-option *ngIf="allowESRESCARD"
                        value="ES_RESIDENCY_CARD">{{ 'ID_TYPES.SPANISH_RESIDENCY_CARD'|translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
  <ng-container *ngIf="entityTypeForm.value.entityType === 'DNI'">
    <form [formGroup]="dniForm" id="dniForm" (submit)="submitForm()">
      <div class="columns">
        <div class="column is-full-mobile is-half">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'DNINUMBER' | translate }}</mat-label>
            <input type="text" appFormInput matInput mask="00000000-S" formControlName="dniNumber"/>
            <mat-error *ngIf="dniForm.get('dniNumber')!.hasError('required')"
                       [innerHtml]="'VALIDATIONMESSAGES.DNINUMBERISREQUIRED' | translate">
            </mat-error>
            <mat-error *ngIf="dniForm.get('dniNumber')!.hasError('invalidFormat')"
                       [innerHtml]="'VALIDATIONMESSAGES.DNINIEFORMAT' | translate">
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column is-full-mobile is-half">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'PASSPORT_EXPIRY_DATE' | translate }}</mat-label>
            <input appFormInput matInput maskedDate [matDatepicker]="dniExpDatePicker" formControlName="expirationDate">
            <mat-datepicker-toggle matSuffix [for]="dniExpDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dniExpDatePicker></mat-datepicker>
            <mat-error *ngIf="dniForm.get('expirationDate')!.hasError('required')"
                       [innerHtml]="'VALIDATIONMESSAGES.DNIEXPIRYDATEREQUIRED' | translate">
            </mat-error>
            <mat-error *ngIf="dniForm.get('expirationDate')!.hasError('expired')"
                       [innerHtml]="'VALIDATIONMESSAGES.NOTVALID' | translate">
            </mat-error>
            <mat-error *ngIf="dniForm.get('expirationDate')!.hasError('invalidDateFormat')"
                       [innerHtml]="'VALIDATIONMESSAGES.INVALIDDATEFORMAT' | translate">
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <hr>
      <div class="field">
        <button type="submit" [disabled]="buttonBlocked || !dniForm.valid || !entityTypeForm.valid"
                class="button is-block is-primary is-fullwidth is-medium mt-5">{{ 'NEXT' | translate }}
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="entityTypeForm.value.entityType === 'ES_RESIDENCY_CARD'">
    <form [formGroup]="dniForm" id="esResidencyForm" (submit)="submitForm()">
      <div class="columns">
        <div class="column is-full-mobile is-half">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'NIENUMBER' | translate }}</mat-label>
            <input type="text" appFormInput matInput mask="S-0000000-S" formControlName="dniNumber"/>
            <mat-error *ngIf="dniForm.get('dniNumber')!.hasError('required')"
                       [innerHtml]="'VALIDATIONMESSAGES.ESRESIDENCYCARDNUMBERREQUIRED' | translate">
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column is-full-mobile is-half">
          <mat-form-field appearance="outline">
            <mat-label> {{ 'PASSPORT_EXPIRY_DATE' | translate }}</mat-label>
            <input appFormInput matInput maskedDate [matDatepicker]="EsResCardExpDatePicker"
                   formControlName="expirationDate">
            <mat-datepicker-toggle matSuffix [for]="EsResCardExpDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #EsResCardExpDatePicker></mat-datepicker>
            <mat-error *ngIf="dniForm.get('expirationDate')!.hasError('required')"
                       [innerHtml]="'VALIDATIONMESSAGES.ESRESIDENCYCARDEXPIRYDATEREQUIRED' | translate">
            </mat-error>
            <mat-error *ngIf="dniForm.get('expirationDate')!.hasError('expired')"
                       [innerHtml]="'VALIDATIONMESSAGES.NOTVALID' | translate">
            </mat-error>
            <mat-error *ngIf="dniForm.get('expirationDate')!.hasError('invalidDateFormat')"
                       [innerHtml]="'VALIDATIONMESSAGES.INVALIDDATEFORMAT' | translate">
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <hr>
      <div class="field">
        <button type="submit" [disabled]="buttonBlocked || !dniForm.valid || !entityTypeForm.valid"
                class="button is-block is-primary is-fullwidth is-medium mt-5">{{ 'NEXT' | translate }}
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container
    *ngIf="entityTypeForm.value.entityType === 'ID' || entityTypeForm.value.entityType === 'PASSPORT_NUMBER' ">
    <div class="no_background">
      <form [formGroup]="passportForm" (submit)="submitForm()">
        <ng-container *ngIf="entityTypeForm.value.entityType === 'ID'">
          <div class="columns">
            <div class="column is-full-mobile is-one-third">
              <mat-form-field appearance="outline">
                <mat-label> {{ 'PASSPORT_ISSUING_COUNTRY' | translate }}</mat-label>
                <mat-select formControlName="issuingCountry">
                  <mat-option value="AT">{{ 'COUNTRIES.AUSTRIA' | translate }}</mat-option>
                  <mat-option value="BE">{{ 'COUNTRIES.BELGIUM' | translate }}</mat-option>
                  <mat-option value="BG">{{ 'COUNTRIES.BULGARIA' | translate }}</mat-option>
                  <mat-option value="HR">{{ 'COUNTRIES.CROATIA' | translate }}</mat-option>
                  <mat-option value="CY">{{ 'COUNTRIES.CYPRUS' | translate }}</mat-option>
                  <mat-option value="CZ">{{ 'COUNTRIES.CZECH' | translate }}</mat-option>
                  <mat-option value="EE">{{ 'COUNTRIES.ESTONIA' | translate }}</mat-option>
                  <mat-option value="FI">{{ 'COUNTRIES.FINLAND' | translate }}</mat-option>
                  <mat-option value="FR">{{ 'COUNTRIES.FRANCE' | translate }}</mat-option>
                  <mat-option value="DE">{{ 'COUNTRIES.GERMANY' | translate }}</mat-option>
                  <mat-option value="GR">{{ 'COUNTRIES.GREECE' | translate }}</mat-option>
                  <mat-option value="HU">{{ 'COUNTRIES.HUNGARY' | translate }}</mat-option>
                  <mat-option value="IT">{{ 'COUNTRIES.ITALY' | translate }}</mat-option>
                  <mat-option value="LV">{{ 'COUNTRIES.LATVIA' | translate }}</mat-option>
                  <mat-option value="LT">{{ 'COUNTRIES.LITHUANIA' | translate }}</mat-option>
                  <mat-option value="LU">{{ 'COUNTRIES.LUXEMBOURG' | translate }}</mat-option>
                  <mat-option value="MT">{{ 'COUNTRIES.MALTA' | translate }}</mat-option>
                  <mat-option value="NL">{{ 'COUNTRIES.NETHERLANDS' | translate }}</mat-option>
                  <mat-option value="NO">{{ 'COUNTRIES.NORWAY' | translate }}</mat-option>
                  <mat-option value="PL">{{ 'COUNTRIES.POLAND' | translate }}</mat-option>
                  <mat-option value="PT">{{ 'COUNTRIES.PORTUGAL' | translate }}</mat-option>
                  <mat-option value="RO">{{ 'COUNTRIES.ROMANIA' | translate }}</mat-option>
                  <mat-option value="SK">{{ 'COUNTRIES.SLOVAKIA' | translate }}</mat-option>
                  <mat-option value="ES">{{ 'COUNTRIES.SPAIN' | translate }}</mat-option>
                  <mat-option value="SE">{{ 'COUNTRIES.SWEDEN' | translate }}</mat-option>
                  <mat-option value="CH">{{ 'COUNTRIES.SWITZERLAND' | translate }}</mat-option>
                  <mat-option value="GB">{{ 'COUNTRIES.UNITEDKINGDOM' | translate }}</mat-option>
                </mat-select>
                <mat-error *ngIf="passportForm.get('issuingCountry')!.hasError('required')"
                           [innerHtml]="'VALIDATIONMESSAGES.IDCOUNTRYREQUIRED' | translate">
                </mat-error>
              </mat-form-field>
            </div>
            <div class="column is-full-mobile is-one-third">
              <mat-form-field appearance="outline">
                <mat-label> {{ 'ID_DOC_NUMBER' | translate }}</mat-label>
                <input type="text" appFormInput matInput formControlName="passportNumber"/>
                <mat-error *ngIf="passportForm.get('passportNumber')!.hasError('required')"
                           [innerHtml]="'VALIDATIONMESSAGES.IDNUMBERREQUIRED' | translate">
                </mat-error>
              </mat-form-field>
            </div>
            <div class="column is-full-mobile is-one-third">
              <mat-form-field appearance="outline">
                <mat-label> {{ 'PASSPORT_EXPIRY_DATE' | translate }}</mat-label>
                <input appFormInput matInput maskedDate [matDatepicker]="IdExpDatePicker"
                       formControlName="expirationDate">
                <mat-datepicker-toggle matSuffix [for]="IdExpDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #IdExpDatePicker></mat-datepicker>
                <mat-error *ngIf="passportForm.get('expirationDate')!.hasError('required')"
                           [innerHtml]="'VALIDATIONMESSAGES.IDEXPIRYDATEREQUIRED' | translate">
                </mat-error>
                <mat-error *ngIf="passportForm.get('expirationDate')!.hasError('expired')"
                           [innerHtml]="'VALIDATIONMESSAGES.NOTVALID' | translate">
                </mat-error>
                <mat-error *ngIf="passportForm.get('expirationDate')!.hasError('invalidDateFormat')"
                           [innerHtml]="'VALIDATIONMESSAGES.INVALIDDATEFORMAT' | translate">
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="entityTypeForm.value.entityType === 'PASSPORT_NUMBER' ">
          <div class="columns">
            <div class="column is-full-mobile is-one-third">
              <mat-select-country appearance="outline" label="{{'PASSPORT_ISSUING_COUNTRY' | translate}}"
                                  [countries]="allowedCountries" [value]="passportCountry"
                                  (onCountrySelected)="onPassportCountryChange($event)">
              </mat-select-country>
            </div>
            <div class="column is-full-mobile is-one-third">
              <mat-form-field appearance="outline">
                <mat-label> {{ 'PASSPORTNUMBER' | translate }}</mat-label>
                <input type="text" appFormInput matInput formControlName="passportNumber"/>
                <mat-error *ngIf="passportForm.get('passportNumber')!.hasError('required')"
                           [innerHtml]="'VALIDATIONMESSAGES.PASSPORTNUMBERREQUIRED' | translate">
                </mat-error>
              </mat-form-field>
            </div>
            <div class="column is-full-mobile is-one-third">
              <mat-form-field appearance="outline">
                <mat-label> {{ 'PASSPORT_EXPIRY_DATE' | translate }}</mat-label>
                <input appFormInput matInput maskedDate [matDatepicker]="PassportExpDatePicker"
                       formControlName="expirationDate">
                <mat-datepicker-toggle matSuffix [for]="PassportExpDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #PassportExpDatePicker></mat-datepicker>
                <mat-error *ngIf="passportForm.get('expirationDate')!.hasError('required')"
                           [innerHtml]="'VALIDATIONMESSAGES.PASSPORTEXPIRYDATEREQUIRED' | translate">
                </mat-error>
                <mat-error *ngIf="passportForm.get('expirationDate')!.hasError('expired')"
                           [innerHtml]="'VALIDATIONMESSAGES.NOTVALID' | translate">
                </mat-error>
                <mat-error *ngIf="passportForm.get('expirationDate')!.hasError('invalidDateFormat')"
                           [innerHtml]="'VALIDATIONMESSAGES.INVALIDDATEFORMAT' | translate">
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <div class="field">
          <button type="submit"
                  [disabled]="buttonBlocked || !passportForm.valid || !entityTypeForm.valid "
                  class="button is-block is-primary is-fullwidth is-medium mt-5">{{ 'NEXT' | translate }}
          </button>
        </div>
      </form>
    </div>
  </ng-container>

</div>

