<div class="frame1000001824-container">
  <div class="frame1000001824-frame1000001824">
    <div class="frame1000001824-pricing shadow">
      <div class="frame1000001824-frame1000001838">
        <div class="frame1000001824-frame1000001835">
          <span class="frame1000001824-text has-text-weight-bold" [ngClass]="{'is-size-3': show6monthsOffer}">
            <span
              [innerHTML]="(show6monthsOffer ? 'SUBSCRIPTION_PLANS.MONTHLY.OFFER_TITLE': 'SUBSCRIPTION_PLANS.MONTHLY.TITLE')|translate"></span>
          </span>
          <div class="frame1000001824-frame1000001830">
            <div class="frame1000001824-frame1000001747">
              <span class="frame1000001824-text02"><span>€9.90</span></span>
              <span class="frame1000001824-text04 has-text-weight-bold">
                <span>/ {{ "SUBSCRIPTION_PLANS.MONTHLY.SUBTITLE"|translate }}</span>
              </span>
            </div>
            <span class="frame1000001824-text06 Smallertext14|Regular">
              <span
                [innerHTML]="(show6monthsOffer ? 'SUBSCRIPTION_PLANS.MONTHLY.6MONTHS_OFFER' : 'SUBSCRIPTION_PLANS.MONTHLY.DESCRIPTION')|translate "></span>
            </span>
          </div>
        </div>
        <img src="/line320i654-bknn.svg" alt="Line320I654" class="frame1000001824-line320" />
        <div class="frame1000001824-frame1000001837">
          <div class="frame1000001824-frame1000001836">
            <span class="frame1000001824-text08 has-text-weight-bold">
              <span>{{ "SUBSCRIPTION_PLANS.FEATURES.LIST_HEADER"|translate }}</span>
            </span>
            <div class="frame1000001824-frame1000001833">
              <div class="frame1000001824-frame1000001831">
                <img src="/assets/img/chevron-down-solid.svg" class="frame1000001824-checked144" />
                <span class="frame1000001824-text10 Caption12|Regular">
                  <span>{{ "SUBSCRIPTION_PLANS.FEATURES.LIST_ITEM_1"|translate }}</span>
                </span>
              </div>
              <div class="frame1000001824-frame1000001832">
                <img src="/assets/img/chevron-down-solid.svg" alt="checked144I654"
                  class="frame1000001824-checked14401" />
                <span class="frame1000001824-text12 Caption12|Regular">
                  <span>{{ "SUBSCRIPTION_PLANS.FEATURES.LIST_ITEM_2"|translate }}</span>
                </span>
              </div>
              <div class="frame1000001824-frame10000018331">
                <img src="/assets/img/chevron-down-solid.svg" alt="checked144I654"
                  class="frame1000001824-checked14402" />
                <span class="frame1000001824-text14 Caption12|Regular">
                  <span>{{ "SUBSCRIPTION_PLANS.FEATURES.LIST_ITEM_3"|translate }}</span>
                </span>
              </div>
              <div class="frame1000001824-frame1000001834">
                <img src="/assets/img/chevron-down-solid.svg" alt="checked144I654"
                  class="frame1000001824-checked14403" />
                <span class="frame1000001824-text16 Caption12|Regular">
                  <span>{{ "SUBSCRIPTION_PLANS.FEATURES.LIST_ITEM_4"|translate }}</span>
                </span>
              </div>
            </div>
          </div>
          <button class="frame1000001824-button" (click)="subscribeMonthly()">
            <div class="frame1000001824-text18">
              <span class="frame1000001824-text19 has-text-weight-bold">
                <span>{{ "SUBSCRIPTION_PLANS.MONTHLY.BUTTON"|translate }}</span>
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="frame1000001824-pricing1 shadow" *ngIf="!show6monthsOffer">
      <div class="frame1000001824-frame10000018381">
        <div class="frame1000001824-frame10000018351">
          <span class="frame1000001824-text21 has-text-weight-bold">
            <span>{{ "SUBSCRIPTION_PLANS.ANNUAL.TITLE"|translate }}</span>
          </span>
          <div class="frame1000001824-frame10000018301">
            <div class="frame1000001824-frame10000017471">
              <span class="frame1000001824-text23"><span>€99.00</span></span>
              <span class="frame1000001824-text25 has-text-weight-bold">
                <span>/ {{ "SUBSCRIPTION_PLANS.ANNUAL.SUBTITLE"|translate }}</span>
              </span>
            </div>
            <span class="frame1000001824-text27 Smallertext14|Regular">
              <span>{{ (showOffer ? "SUBSCRIPTION_PLANS.ANNUAL.WELCOME_OFFER" :
                "SUBSCRIPTION_PLANS.ANNUAL.DESCRIPTION")|translate }}</span>
            </span>
          </div>
        </div>

        <img src="/line320i654-lh1f.svg" alt="Line320I654" class="frame1000001824-line3201" />
        <div class="frame1000001824-frame10000018371">
          <div class="frame1000001824-frame10000018361">
            <span class="frame1000001824-text29 has-text-weight-bold">
              <span>{{ "SUBSCRIPTION_PLANS.FEATURES.LIST_HEADER"|translate }}</span>
            </span>
            <div class="frame1000001824-frame10000018332">
              <div class="frame1000001824-frame10000018311">
                <img src="/assets/img/chevron-down-solid.svg" alt="checked144I654"
                  class="frame1000001824-checked14404" />
                <span class="frame1000001824-text31 Caption12|Regular">
                  <span>{{ "SUBSCRIPTION_PLANS.FEATURES.LIST_ITEM_1"|translate }}</span>
                </span>
              </div>
              <div class="frame1000001824-frame10000018321">
                <img src="/assets/img/chevron-down-solid.svg" alt="checked144I654"
                  class="frame1000001824-checked14405" />
                <span class="frame1000001824-text33 Caption12|Regular">
                  <span>{{ "SUBSCRIPTION_PLANS.FEATURES.LIST_ITEM_2"|translate }}</span>
                </span>
              </div>
              <div class="frame1000001824-frame10000018333">
                <img src="/assets/img/chevron-down-solid.svg" alt="checked144I654"
                  class="frame1000001824-checked14406" />
                <span class="frame1000001824-text35 Caption12|Regular">
                  <span>{{ "SUBSCRIPTION_PLANS.FEATURES.LIST_ITEM_3"|translate }}</span>
                </span>
              </div>
              <div class="frame1000001824-frame10000018341">
                <img src="/assets/img/chevron-down-solid.svg" alt="checked144I654"
                  class="frame1000001824-checked14407" />
                <span class="frame1000001824-text37 Caption12|Regular">
                  <span>{{ "SUBSCRIPTION_PLANS.FEATURES.LIST_ITEM_4"|translate }}</span>
                </span>
              </div>
            </div>
          </div>
          <button class="frame1000001824-button1" (click)="subscribeYearly()">
            <div class="frame1000001824-text43">
              <span class="frame1000001824-text44 has-text-weight-bold">
                <span>{{ "SUBSCRIPTION_PLANS.ANNUAL.BUTTON"|translate }}</span>
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>