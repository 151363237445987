export const environment = {
  production: false,
  requireHttps: true,
  underMaintenance: false,
  appBaseUrl: 'https://app-dev.getbueno.com/',
  apiBaseUrl: 'https://api-dev.getbueno.com/api',
  identityServerUrl: 'https://security-dev.getbueno.com',
  appStoreUrl: 'https://apps.apple.com/es/app/bueno/id6446980287?l=en-GB',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=bueno.banking.app',
  buenoCMSUrl: 'https://cms-api.getbueno.com',
  idpClientId: 'BuenoBankClient',
  marqetaApplicationID: 'hubuc_sandbox',
  marqetaUrl: 'https://widgets-sandbox.marqeta.com',
  subscriptionProducts: {
    month: 'price_1KBdA1GNA5PYvgdDBaNtQmmS',
    annual: 'price_1KjmcKGNA5PYvgdDeqzfc5eI'
  },
  onePaymentProducts: {
    annual: 'price_1KOOMpGNA5PYvgdDX4tCK0CI'
  }
};