<div style="padding: 1%;margin-top: 1%;" *ngIf="step !== 'completed'">
  <p class="title is-size-4">{{'VERIFYMOBILE' | translate}}</p>
  <form [formGroup]="mobileForm" (submit)="submitForm()" *ngIf="step === 'init'">
    <article class="message is-info">
      <div class="message-body px-0 py-3 content" style="border:0px;">
        <p>{{'MOBILEVERIFICATIONINFO.WAITFORSMS' | translate}}</p>
        <p>{{'MOBILEVERIFICATIONINFO.ENTERCODE' | translate}}</p>
      </div>
    </article>
    <mat-form-field appearance="outline" style="text-align: center;">
      <mat-label> {{ 'COUNTRYCODE' | translate }} </mat-label>
      <mat-select formControlName="mobilePhonePrefix">
        <mat-option value="43">{{'COUNTRIES.AUSTRIA' | translate}} (+43)</mat-option>
        <mat-option value="32">{{'COUNTRIES.BELGIUM' | translate}} (+32)</mat-option>
        <mat-option value="359">{{'COUNTRIES.BULGARIA' | translate}} (+359)</mat-option>
        <mat-option value="385">{{'COUNTRIES.CROATIA' | translate}} (+385)</mat-option>
        <mat-option value="357">{{'COUNTRIES.CYPRUS' | translate}} (+357)</mat-option>
        <mat-option value="420">{{'COUNTRIES.CZECH' | translate}} (+420)</mat-option>
        <mat-option value="45">{{'COUNTRIES.DENMARK' | translate}} (+45)</mat-option>
        <mat-option value="372">{{'COUNTRIES.ESTONIA' | translate}} (+372)</mat-option>
        <mat-option value="358">{{'COUNTRIES.FINLAND' | translate}} (+358)</mat-option>
        <mat-option value="33">{{'COUNTRIES.FRANCE' | translate}} (+33)</mat-option>
        <mat-option value="49">{{'COUNTRIES.GERMANY' | translate}} (+49)</mat-option>
        <mat-option value="30">{{'COUNTRIES.GREECE' | translate}} (+30)</mat-option>
        <mat-option value="36">{{'COUNTRIES.HUNGARY' | translate}} (+36)</mat-option>
        <mat-option value="354">{{'COUNTRIES.ICELAND' | translate}} (+354)</mat-option>
        <mat-option value="353">{{'COUNTRIES.IRELAND' | translate}} (+353)</mat-option>
        <mat-option value="39">{{'COUNTRIES.ITALY' | translate}} (+39)</mat-option>
        <mat-option value="371">{{'COUNTRIES.LATVIA' | translate}} (+371)</mat-option>
        <mat-option value="423">{{'COUNTRIES.LIECHTENSTEIN' | translate}} (+423)</mat-option>
        <mat-option value="370">{{'COUNTRIES.LITHUANIA' | translate}} (+370)</mat-option>
        <mat-option value="352">{{'COUNTRIES.LUXEMBOURG' | translate}} (+352)</mat-option>
        <mat-option value="356">{{'COUNTRIES.MALTA' | translate}} (+356)</mat-option>
        <mat-option value="31">{{'COUNTRIES.NETHERLANDS' | translate}} (+31)</mat-option>
        <mat-option value="47">{{'COUNTRIES.NORWAY' | translate}} (+47)</mat-option>
        <mat-option value="48">{{'COUNTRIES.POLAND' | translate}} (+48)</mat-option>
        <mat-option value="351">{{'COUNTRIES.PORTUGAL' | translate}} (+351)</mat-option>
        <mat-option value="40">{{'COUNTRIES.ROMANIA' | translate}} (+40)</mat-option>
        <mat-option value="421">{{'COUNTRIES.SLOVAKIA' | translate}} (+421)</mat-option>
        <mat-option value="386">{{'COUNTRIES.SLOVENIA' | translate}} (+386)</mat-option>
        <mat-option value="34">{{'COUNTRIES.SPAIN' | translate}} (+34)</mat-option>
        <mat-option value="46">{{'COUNTRIES.SWEDEN' | translate}} (+46)</mat-option>
        <mat-option value="41">{{'COUNTRIES.SWITZERLAND' | translate}} (+41)</mat-option>
        <mat-option value="44">{{'COUNTRIES.UNITEDKINGDOM' | translate}} (+44)</mat-option>
        <mat-option value="690">Tokelau (+690)</mat-option>
      </mat-select>
      <mat-error *ngIf="mobileForm.get('mobilePhonePrefix')!.hasError('required')"
        [innerHtml]="'VALIDATIONMESSAGES.MOBILENUMBERREQUIRED' | translate">
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label> {{ 'MOBILEPHONE' | translate }} </mat-label>
      <input style="text-align: center; color: #010221de;" type="text" matInput formControlName="mobilePhoneNumber" />
      <mat-error *ngIf="mobileForm.get('mobilePhoneNumber')!.hasError('required')"
        [innerHtml]="'VALIDATIONMESSAGES.MOBILENUMBERREQUIRED' | translate">
      </mat-error>
    </mat-form-field>
    <div class="field mt-3">
      <button type="submit" class="button is-primary" style="white-space: normal;height: auto;">{{'REQUESTSMSCODE' |
        translate}}</button>
    </div>
  </form>
  <form [formGroup]="mobileValidationForm" *ngIf="step === 'send-code'" (submit)="submitCode()">
    <div class="columns">
      <div class="column" *ngIf="invalidCode">
        <div class="message is-danger">
          <div class="message-body content">
            <p class="title is-size-6">{{'INVALIDCODE' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="main_container_padding">
      <code-input class="code-input" (codeCompleted)="onOTPCodeCompleted($event)" [codeLength]="4"></code-input>
    </div>
    <div class="field m-3">
      <button type="submit" [disabled]="!mobileValidationForm.valid" class="button is-primary">{{'SENDCODE' |
        translate}}</button>
    </div>
    <div class="columns">
      <div class="column mt-2">
        <p class="mb-3">{{'MAYBEWRONGPHONENUMBER' | translate}}</p>
        <button #codeInput class="button is-link" style="white-space: normal;height: auto;max-width: 35rem;"
          (click)="step = 'init'">{{'CHECKNUMBER' | translate}}</button>
      </div>
    </div>
  </form>
</div>
<div class="message is-success" style="padding: 1%;margin-top: 1%;" *ngIf="step === 'completed'">
  <div class="message-header">
    <p>{{'SUCCESS' | translate}}</p>
    <button class="delete" aria-label="delete" (click)="finalise()"></button>
  </div>
  <div class="message-body">
    <p class="title is-size-5 mb-4">
      {{'MOBILEPHONECONFIRMEDEXP' | translate}}
    </p>
    <button class="button" (click)="finalise()">{{'OK' | translate}}</button>
  </div>
</div>
<div class="notification is-danger mt-5" *ngIf="showTooManyAttempts">
  <button class="delete" (click)="showTooManyAttempts = false"></button>
  {{'TOMANYATTEMPTS' | translate}}
</div>