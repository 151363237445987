import { AdditionalAddress } from './additional-address';
import { CardDeliveryAddress } from './card-delivery.address';
import { FinancialDetails } from './financial-details';

export class UserInfo {
  id!: string;
  firstName!: string;
  middleName!: string;
  lastName!: string;
  email!: string;
  countryCode!: string;
  phoneCountryCode!: any;
  address!: string;
  hasProperty!: boolean;
  city!: string;
  state!: string;
  postalCode!: string;
  birthday!: Date;
  created!: Date;
  roles!: string[];
  modules!: string[];
  nationality!: string;
  isEmailConfirmed!: boolean;
  isPhoneConfirmed!: boolean;
  backupEmail!: string;
  preferredLanguage!: string;
  isBackupEmailConfirmed!: boolean;
  additionalAddresses!: AdditionalAddress[];
  identificationInfo!: IdentificationInfo[];
  providerUserInfos!: ProviderUserInfo[];
  phoneNumber!: string;
  deliveryAddress!: CardDeliveryAddress;
  subscriptionsHistory!: SubscriptionDetail[];
  kycInitialized!: boolean;
  kycPending!: boolean;
  address2: any;
  financialDetails!: FinancialDetails;
  uploadedDocuments!: UploadedDocument[];
  signedDocuments!: SignedDocument[];
  screeningResults!: ScreeningResult;
  legalAddress: any;
  interest!: any;
  specialOfferEligible!: boolean;
}
export class IdentificationInfo {
  type!: string;
  value!: string;
  issuingCountry!: string;
  expirationDate!: Date;
}

export class ScreeningResult {
  screeningProgress!: string;
  riskClassificationStatus!: string;
  customerVettingStatus!: string;
  statusColor!: string;
}

export class ProviderUserInfo {
  externalUserId!: string;
  provider!: 0;
  isVerified!: boolean;
  verifiedDate!: Date;
  ledgerToken: any;
  iban: any;
  oldIban: any;
  kycLevel!: number;
  verificationStatus!: string;
}

export class SubscriptionDetail {
  paymentType!: number;
  period!: number;
  created!: Date;
  paidUntil!: Date;
  isActive!: boolean;
  stripePriceId!: string;
  stripeCustomerId!: string;
  isTrialActive!: boolean;
}

export class UploadedDocument {
  fileId!: string;
  fileName!: string;
  type!: string;
}

export class SignedDocument {
  documentType!: number;
  timestamp!: Date;
}
