import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faCircle, faArrowUpRight, faArrowDownRight } from '@fortawesome/pro-solid-svg-icons';
import { UserBankingAccount } from './../shared/models/user-banking-account';
import { UserService } from '../core/services/user.service';
import { AuthService } from '../core/auth.service';
import { UpcomingPaymentsService } from '../core/services/upcoming-payments.service';
import { TransfersService } from '../core/services/transfers.service';
import { UserInfo } from '../models/user-info';
import { UpcomingPayment } from '../shared/models/upcoming-payment';
import { Transaction } from '../models/transaction';
import { TransactionsList } from '../models/transactions-list';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  user: UserInfo = {} as UserInfo;
  authenticated = false;
  // FontAwesome icons
  faCircle = faCircle;
  faUpRight = faArrowUpRight;
  faDownRight = faArrowDownRight;
  // Other properties
  accounts: UserBankingAccount[] = [];
  loading = true;
  upComingPayments: UpcomingPayment[] = [];
  transactionsLists: TransactionsList[] = [];
  emptyTransactionList = false;
  emptyUpcomingPayments = false;
  isMobile = window.innerWidth <= 770;
  isSubscribed = false;
  onboarded = false;
  showMigrationPopup = false;

  constructor(
    private userService: UserService,
    private transfersService: TransfersService,
    private upComingSvc: UpcomingPaymentsService,
    private authService: AuthService,
    private router: Router
  ) {
    this.subscribeToAuthChanges();
  }

  private subscribeToAuthChanges(): void {
    this.authService.isAuthenticated$.subscribe((res) => {
      this.handleAuthChange(res);
    });
  }

  private handleAuthChange(isAuthenticated: boolean): void {
    if (isAuthenticated !== this.authenticated) {
      this.authenticated = isAuthenticated;
      if (isAuthenticated) {
        this.onAuthSuccess();
      }
    }
  }

  private onAuthSuccess(): void {
    this.upComingPayments = this.upComingSvc.getMockData();
    this.emptyUpcomingPayments = this.upComingPayments.length === 0;
    this.loadUserInfo();
  }

  private loadUserInfo(): void {
    this.userService.getUserInfo().subscribe((u) => {
      if (u) {
        this.user = u;
        this.checkIfOnboarded();
        if (this.onboarded && this.isSubscribed) {
          this.getUserAccounts();
          this.getTransfersHistory();
        }
      } else {
        this.loading = false;
      }
    },
      (error) => {
        console.error('Error loading user info', error);
        this.loading = false;
      }
    );
  }

  private checkIfOnboarded(): void {
    this.onboarded = this.user.subscriptionsHistory?.length > 0 && this.user.providerUserInfos?.some(u => u.ledgerToken && u.iban);
    if (!this.onboarded && this.user.firstName) {
      this.router.navigate(['/onboarding']);
    } else {
      this.isSubscribed = this.user.subscriptionsHistory?.some(x => new Date(x.paidUntil) > new Date());
    }
  }

  private getUserAccounts(): void {
    this.accounts = [];
    if (this.user.providerUserInfos && this.user.providerUserInfos.length > 0) {
      const providerInfo = this.user.providerUserInfos[0];
      const account = new UserBankingAccount();
      account.ibanNumber = providerInfo.iban;
      account.currency = 'EUR';
      account.type = 'SEPA';
      this.accounts.push(account);
    }
  }

  private getTransfersHistory(): void {
    this.transfersService.getTransactions('', 0, 20).subscribe((transactionsList) => {
      this.transactionsLists = this.filterTransactions(transactionsList.transactionsLists);
      this.loading = false;
      this.emptyTransactionList = this.transactionsLists.length === 0;
    },
      (error) => {
        console.error('Error loading transactions', error);
        this.loading = false;
      }
    );
  }

  private filterTransactions(transactionsLists: TransactionsList[]): TransactionsList[] {
    if (transactionsLists.length === 0) {
      return this.generateFakeTransactions();
    }

    const filteredTransactionsLists: TransactionsList[] = [];
    let transactionsCounter = 0;

    for (const transactionsList of transactionsLists) {
      if (transactionsCounter >= 7) break;
      const newTransactionsList = new TransactionsList();
      newTransactionsList.year = transactionsList.year;
      newTransactionsList.monthLabel = transactionsList.monthLabel;
      newTransactionsList.dateForOrdering = transactionsList.dateForOrdering;
      newTransactionsList.Transactions = [];

      for (const transaction of transactionsList.Transactions) {
        if (transactionsCounter >= 7) break;
        newTransactionsList.Transactions.push(transaction);
        transactionsCounter++;
      }

      filteredTransactionsLists.push(newTransactionsList);
    }
    return filteredTransactionsLists;
  }

  private generateFakeTransactions(): TransactionsList[] {
    const fakeTransactionsLists: TransactionsList[] = [];
    const transList = new TransactionsList();
    transList.dateForOrdering = new Date(2021, 11, 15);
    transList.year = transList.dateForOrdering.getUTCFullYear();
    transList.monthLabel = transList.dateForOrdering.toLocaleDateString('default', {
      day: '2-digit',
      month: 'long',
    });

    const transaction = new Transaction();
    transaction.created_at = new Date().toString();
    transaction.currency_code = 'EUR';
    transaction.payerName = 'SECURITAS';
    transaction.amount = 49.0;
    transaction.action = 'PAYMENT';
    transaction.description = 'Utility payments';
    transaction.actualBalanceAfterMovement = 49.0;

    transList.Transactions = [transaction];
    fakeTransactionsLists.push(transList);

    return fakeTransactionsLists;
  }

  login(): void {
    this.authService.login();
  }

  closeMigrationPopUp(): void {
    this.showMigrationPopup = false;
    localStorage.setItem('oldIbanPopupClosed', 'true');
  }
}
