import { FormGroup } from '@angular/forms';

export function ValidAmount(controlName: string, balance: number): any {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (control.value == null || control.value === '') {
            return;
        } else {
            /// check if the amount is lower or equal than the balance and greater than 0
            if (control.value > balance || control.value <= 0) {
                control.setErrors({ invalidAmount: true });
            } else {
                control.setErrors(null);
            }
        }
    };
}
