import { Component, Input, OnInit, AfterContentInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { PostalCode } from 'src/app/shared/validators/postal-code';
import { NgxGpAutocompleteOptions } from "@angular-magic/ngx-gp-autocomplete";
import { constants } from "../../../constants/constants";
import { AyuwareService } from 'src/app/core/services/ayuware.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-property-settings',
  templateUrl: './property-settings.component.html',
  styleUrls: ['./property-settings.component.scss'],
})
export class PropertySettingsComponent implements OnInit, OnChanges {
  faTimes = faTimes;
  @Input() propertyAddress!: any;
  @Input() userSettings!: boolean;
  property: any;
  showFields!: boolean;
  editMode!: boolean;
  showResidentModal!: boolean;
  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['ES'] },
  };
  isSpanishResident!: boolean;
  autocompleteOptions!: any;
  selectedAddress!: any;
  propertyDetailsMode!: any;
  floorOptions = [];
  doorOptions = [];
  propertyInfo!: any;
  isDevelopment = environment.production === false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private ayuwareService: AyuwareService
  ) { }

  addressForm = this.formBuilder.group(
    {
      streetName: [''
        ,
        {
          validators: [
            Validators.required,
            Validators.pattern(constants.addressPattern),
          ],
          updateOn: 'change',
        },
      ],
      streetNumber: [''
        ,
        { validators: [Validators.required], updateOn: 'change' },
      ],
      blockNumber: ['', { updateOn: 'change' }],
      floorNumber: ['', { updateOn: 'change' }],
      doorNumber: ['', { updateOn: 'change' }],
      city: ['', { validators: [Validators.required], updateOn: 'change' }],
      postalCode: ['', { validators: [Validators.required], updateOn: 'change' }],
      state: ['', { validators: [Validators.required], updateOn: 'change' }],
      country: ['', { validators: [Validators.required], updateOn: 'change' }],
      customAutocomplete: [''],
    },
    {
      validator: PostalCode('postalCode', 'country'),
    }
  );
  propertyForm = this.formBuilder.group({
    propertyType: [
      '0',
      { validators: [Validators.required], updateOn: 'change' },
    ],
    garden: [
      'false',
      { Validators: [Validators.required], updateOn: 'change' },
    ],
    bedrooms: ['', { Validators: [Validators.required], updateOn: 'change' }],
    pool: ['false', { Validators: [Validators.required], updateOn: 'change' }],
    size: ['', { Validators: [Validators.required], updateOn: 'change' }],
  });

  ngOnInit(): void {
    if (this.propertyAddress) {
      this.addressForm.patchValue({
        streetName: this.propertyAddress.nameOfStreet,
        streetNumber: this.propertyAddress.streetNumber,
        blockNumber: this.propertyAddress.blockNumber,
        floorNumber: this.propertyAddress.floorNumber,
        doorNumber: this.propertyAddress.doorNumber,
        country: this.propertyAddress.countryCode,
        postalCode: this.propertyAddress.postalCode,
        state: this.propertyAddress.state,
        city: this.propertyAddress.city,
      });
      this.editMode = true;
      this.showFields = true;
      this.propertyDetailsMode = "basic";
    } else {
      this.addressForm.patchValue({ country: 'ES' });
    }
    this.addressForm.controls['customAutocomplete'].valueChanges.subscribe((value) => {
      this.ayuwareService.autocompleteAddress(value).subscribe((response) => {
        this.autocompleteOptions = response.results;
      });
    });
  }

  validateAddress(address: any) {
    this.selectedAddress = address;
    this.ayuwareService.validateAddress(address).subscribe((response) => {
      this.autofillAddress(response.results[0]);
      if (response.results[0].referencia_catastral) {
        this.ayuwareService.getPropertyData(response.results[0]).subscribe((response) => {
          this.propertyInfo = response.results;
          this.setAvailableOptions(response);
        });
      }
    });
  }
  setAvailableOptions(response: any) {
    this.propertyDetailsMode = "advanced";
    let blockOptions = [];
    this.floorOptions = Array.from(new Set(response.results.map((x: any) => x.piso)));
    this.doorOptions = Array.from(new Set(response.results.map((x: any) => x.puerta)));
    this.floorOptions = this.floorOptions.filter((option: any) => option !== undefined);
    this.doorOptions = this.doorOptions.filter((option: any) => option !== undefined);
    if (this.floorOptions.length === 0 && this.doorOptions.length === 0) {
      this.propertyDetailsMode = "hidden";
    }
  }
  autofillAddress(validAddress: any) {
    let country = validAddress.pais;
    let postalCode = validAddress.cp;
    let state = this.ayuwareService.getProvinceFromCode(validAddress.cod_prov);
    let streetName = `${validAddress.tipo_via} ${validAddress.via}`;
    let streetNumber = validAddress.numero_via;
    let city = validAddress.poblacion;
    if (streetNumber == undefined) {
      streetNumber = this.selectedAddress.numero_via;
    }
    this.addressForm.patchValue({
      country: country ? country : 'ES',
      postalCode: postalCode ? postalCode : '',
      state: state ? state : '',
      streetName: streetName ? streetName : '',
      streetNumber: streetNumber ? streetNumber : '',
      city: city ? city : '',
    });
    this.showFields = true;
    this.propertyDetailsMode = "basic";
    /// force validation
    this.addressForm.controls['streetName'].markAsTouched();
    this.addressForm.controls['streetNumber'].markAsTouched();
    this.addressForm.controls['city'].markAsTouched();
    this.addressForm.controls['postalCode'].markAsTouched();
    this.addressForm.controls['state'].markAsTouched();
    this.addressForm.controls['country'].markAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['propertyAddress'] && changes['propertyAddress'].currentValue) {
      this.addressForm.patchValue({
        streetName: this.propertyAddress.nameOfStreet,
        streetNumber: this.propertyAddress.streetNumber,
        blockNumber: this.propertyAddress.blockNumber,
        floorNumber: this.propertyAddress.floorNumber,
        doorNumber: this.propertyAddress.doorNumber,
        country: this.propertyAddress.countryCode,
        postalCode: this.propertyAddress.postalCode,
        state: this.propertyAddress.state,
        city: this.propertyAddress.city,
      });
      this.editMode = true;
      this.showFields = true;
    }
  }
  getStreetNumber(address: any): any {
    throw new Error('Method not implemented.');
  }

  getStreetName(address: any): any {
    throw new Error('Method not implemented.');
  }

  submitForm(isSpanishResident: boolean): void {
    this.isSpanishResident = isSpanishResident;
    if (this.addressForm.value.postalCode) { // Additional check to avoid unexpected behavior after pressing ENTER
      let houseInfo = this.getHouseInfo();
      const address = {
        address1: `${this.addressForm.value.streetName} ${this.addressForm.value.streetNumber}`,
        address2: `${this.addressForm.value.blockNumber ?? ''} ${this.addressForm.value.floorNumber ?? ''
          } ${this.addressForm.value.doorNumber ?? ''}`,
        city: this.addressForm.value.city,
        state: this.addressForm.value.state,
        countryCode: this.addressForm.value.country,
        postalCode: this.addressForm.value.postalCode,
        province: this.addressForm.value.state,
        nameOfStreet: this.addressForm.value.streetName,
        streetNumber: this.addressForm.value.streetNumber?.toString(),
        blockNumber: this.addressForm.value.blockNumber?.toString(),
        floorNumber: this.addressForm.value.floorNumber?.toString(),
        doorNumber: this.addressForm.value.doorNumber,
        cadastralReference: houseInfo?.referencia_catastral,
        constructionYear: houseInfo?.anno_construccion,
        builtArea: houseInfo?.sup_construida,
        useArea: houseInfo?.sup_util,
        parcelSurface: houseInfo?.sup_parcela,
        stairs: houseInfo?.escalera,
      };
      this.userService.setLegalAddress(address).subscribe(() => {
        if (this.editMode) {
          localStorage.setItem('lastEditedStep', 'SpanishAddress');
        }
        if (!this.userSettings) {
          if (this.isSpanishResident) {
            this.userService.setAddress(address, true).subscribe(() => {
              this.router.navigate(['']);
            });
          } else {
            this.router.navigate(['']);
          }
        }
      });
    }
  }
  getHouseInfo(): any {
    if (this.propertyInfo != undefined) {
      let houseInfo = this.propertyInfo.find((x: any) => x.piso === this.addressForm.value.floorNumber && x.puerta === this.addressForm.value.doorNumber);
      return houseInfo;
    } else {
      return {};
    }
  }
}
