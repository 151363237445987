import { Component } from '@angular/core';
import { UserService } from '../core/services/user.service';
import { Router } from '@angular/router';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../models/user-info';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent {
  // User information
  user!: UserInfo;
  // Current step in the onboarding process
  currentStep = 0;
  // Progress of the onboarding process as a percentage
  progress = 0;
  // Current step name in the onboarding process
  currentStepName: string = '';
  // Max step reached by the user in the onboarding process
  lastFilledStep: any;
  // Flags to track data refresh states
  firstDataRefresh: any;
  dataRefresh: any;
  // Flags to track various states
  hasIbanAndLedger: any;
  isSubscribed = false;
  kycStatus: string = '';
  // Flags for notifications
  showEmailNotification = false;
  showPhoneNotification = false;
  // Icons
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  // Order of onboarding steps
  onboardingSteps = [
    "EmailVerification",
    "Interest",
    "SpanishAddress",
    "ResidentialAddress",
    "noBanking",
    "idPassport",
    "Nie",
    "Financial",
    "PhoneVerification",
    "Kyc",
    "KycPending",
    "Subscription",
    "AccountCreation"
  ];

  constructor(
    private userService: UserService,
    private router: Router,
    private window: Window
  ) { }

  ngOnInit(): void {
    this.fetchUserInfo();
  }

  // Fetch user information from the service
  private fetchUserInfo(): void {
    this.userService.getUserInfo().subscribe((user) => {
      this.user = user;
      const cutoffDate = new Date(2024, 9, 1);
      if (new Date() >= cutoffDate) {
        this.user.specialOfferEligible = false;
      }
      this.isSubscribed = !!(this.user.subscriptionsHistory && this.user.subscriptionsHistory.length > 0);
      this.hasIbanAndLedger = !!(this.user.providerUserInfos && this.user.providerUserInfos.some(info => info.ledgerToken && info.iban));
      if (this.isSubscribed && this.hasIbanAndLedger) {
        this.router.navigate(['/home']);
      } else {
        this.initializeOnboarding();
      }
    });
  }

  // Initialize the onboarding process
  private initializeOnboarding(): void {
    localStorage.removeItem('onboardingStep');
    this.currentStepName = this.getCurrentOnboardingStep();
    this.currentStep = this.onboardingSteps.indexOf(this.currentStepName);
    this.updateProgress();
    this.updateExternalVariables('step', this.currentStepName);
    this.checkLocalStorageNotifications();
    this.checkLastEditedStep();
  }

  // Determine the current step in the onboarding process
  private getCurrentOnboardingStep(): string {
    // return 'Subscription';
    if (!this.user) return 'EmailVerification';
    const { interest, address, legalAddress, identificationInfo, providerUserInfos, kycPending, kycInitialized, isPhoneConfirmed, isEmailConfirmed } = this.user;
    if (!this.hasIbanAndLedger && this.isSubscribed) {
      this.createAccount();
      return 'AccountCreation';
    }
    const kycVerified = providerUserInfos && providerUserInfos.some(info => info.isVerified);
    if (!this.isSubscribed && kycVerified) return 'Subscription';
    if (!kycVerified && kycPending && kycInitialized) return this.handleKycPending();
    if (kycInitialized && !kycPending && !kycVerified) return 'Kyc';
    if (!kycInitialized && isPhoneConfirmed) return 'Kyc';
    if (!isPhoneConfirmed && providerUserInfos?.length) return 'PhoneVerification';
    if (!providerUserInfos?.length && identificationInfo?.length) return this.handleFinancialStep();
    if (!identificationInfo?.length && address) return interest?.banking ? 'idPassport' : 'noBanking';
    if (!address && legalAddress) return 'ResidentialAddress';
    if (!legalAddress && interest?.lastModifiedAt) return 'SpanishAddress';
    if (!interest?.lastModifiedAt && isEmailConfirmed) return 'Interest';
    return 'EmailVerification';
  }

  // Handle KYC pending step
  private handleKycPending(): string {
    if (!this.firstDataRefresh) {
      this.userService.updateUserInfo();
      this.firstDataRefresh = true;
    }
    if (!this.dataRefresh) {
      this.dataRefresh = true;
      this.waitForRefreshUserInfo();
    }
    this.kycStatus = this.user?.providerUserInfos?.[0]?.verificationStatus || '';
    return 'KycPending';
  }

  // Handle financial step based on user identification information
  private handleFinancialStep(): string {
    const needsNie = this.user?.identificationInfo?.some(info => info.type === 'PASSPORT_NUMBER' || (info.type === 'ID' && info.issuingCountry !== 'ES'));
    const nieSubmitted = this.user?.identificationInfo?.some(info => info.type === 'NIE');
    return needsNie && !nieSubmitted ? 'Nie' : 'Financial';
  }

  // Update the progress percentage
  private updateProgress(): void {
    if (this.currentStepName === 'AccountCreation' || this.currentStepName === 'Subscription') {
      this.progress = 100;
    } else {
      this.progress = (this.currentStep / this.onboardingSteps.length) * 100;
    }
  }

  // Update external tracking variables
  private updateExternalVariables(key: string, value: string): void {
    // @ts-ignore
    this.window._mfq.push(['setVariable', key, value]);
  }

  // Check for notifications in local storage
  private checkLocalStorageNotifications(): void {
    if (localStorage.getItem('showEmailNotification') === 'true') {
      this.showEmailNotification = true;
      localStorage.removeItem('showEmailNotification');
    }
    if (localStorage.getItem('showPhoneNotification') === 'true') {
      this.showPhoneNotification = true;
      localStorage.removeItem('showPhoneNotification');
    }
    if (this.progress !== 100) {
      this.lastFilledStep = this.currentStep;
      localStorage.setItem('onboardingStep', this.currentStep.toString());
    }
  }

  // Check the last edited step from local storage and move to the next step instead of the next step that has not yet been completed
  private checkLastEditedStep(): void {
    const lastEditedStepIndex = this.onboardingSteps.indexOf(localStorage.getItem('lastEditedStep') || '');
    if (lastEditedStepIndex != -1 && lastEditedStepIndex < this.currentStep) {
      this.currentStep = lastEditedStepIndex + 1;
      this.currentStepName = this.onboardingSteps[this.currentStep];
      this.updateProgress();
      localStorage.removeItem('lastEditedStep');
    }
  }

  // Navigate to the next onboarding step
  goNextStep(): void {
    const nextStepName = this.getNextStepName();
    this.updateStep(nextStepName);
  }

  // Navigate to the previous onboarding step
  goPreviousStep(): void {
    const previousStepName = this.getPreviousStepName();
    this.updateStep(previousStepName);
  }

  // Get the name of the next onboarding step
  private getNextStepName(): string {
    if (this.currentStepName === 'ResidentialAddress') {
      return this.user?.interest?.banking ? 'idPassport' : 'noBanking';
    }
    if (this.currentStepName === 'idPassport') {
      const needsNie = this.user?.identificationInfo?.some(info => info.type === 'PASSPORT_NUMBER' || (info.type === 'ID' && info.issuingCountry !== 'ES'));
      return needsNie ? 'Nie' : 'Financial';
    }
    return this.onboardingSteps[this.currentStep + 1];
  }

  // Get the name of the previous onboarding step
  private getPreviousStepName(): string {
    if (this.currentStepName === 'noBanking') return 'ResidentialAddress';
    if (this.currentStepName === 'idPassport') return 'ResidentialAddress';
    if (this.currentStepName === 'Nie') return 'idPassport';
    if (this.currentStepName === 'Financial') {
      const needsNie = this.user?.identificationInfo?.some(info => info.type === 'PASSPORT_NUMBER' || (info.type === 'ID' && info.issuingCountry !== 'ES'));
      return needsNie ? 'Nie' : 'idPassport';
    }
    return this.onboardingSteps[this.currentStep - 1];
  }

  // Update the current step and progress
  private updateStep(stepName: string): void {
    this.currentStepName = stepName;
    this.currentStep = this.onboardingSteps.indexOf(stepName);
    this.updateProgress();
  }

  // Trigger yearly payment using Stripe
  yearlyPayment(): void {
    this.userService.stripeOnePayment(environment.onePaymentProducts.annual).subscribe(() => {
      this.userService.updateUserInfo().subscribe(() => this.router.navigate(['/payments/success-invoice']));
    });
  }

  // Trigger monthly payment using Stripe
  monthlyPayment(): void {
    this.userService.freeFirstMonth().subscribe(() => { });
  }

  specialOfferPayment(): void {
    this.userService.specialOffer().subscribe(() => { });
  }

  // Create user account
  createAccount(): void {
    this.userService.createAccount().subscribe(() => { });
  }

  // Change user's banking preference
  changeBankingPreference(): void {
    let preferences = this.user?.interest ?? {};
    preferences.banking = true;
    this.userService.saveUserPreferences(preferences).subscribe(() => { });
  }

  // Helper method to introduce a delay
  sleep(ms: number | undefined): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Wait for user info to refresh
  async waitForRefreshUserInfo(): Promise<void> {
    await this.sleep(60000);
    this.dataRefresh = false;
    this.userService.updateUserInfo().subscribe(() => { });
  }
}
