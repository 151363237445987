import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BuenoWebApiResult } from 'src/app/shared/models/bueno-api-response';
import { OAuthService } from 'angular-oauth2-oidc';
import { SendTransfer } from '../../user/models/send-transfer';
import { ConfirmTransfer } from '../../user/models/confirm-transfer';
import { Transaction } from 'src/app/models/transaction';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { TransactionSender } from 'src/app/models/transaction-sender';
import { TransactionsList } from 'src/app/models/transactions-list';
import { environment } from 'src/environments/environment';
import { ScheduledTransfer } from 'src/app/user/models/scheduled-transfer';

@Injectable({
  providedIn: 'root'
})
export class TransfersService {
  private url: string;

  constructor(private httpClient: HttpClient,
    private oAuth: OAuthService,
    @Inject('BASE_API_URL') private baseApiUrl: string,
    private baseApiService: BaseApiService) {
    this.url = `${this.baseApiUrl}`;
  }

  getTransactions(search: any, index: any, pageSize: any, startDate?: any, endDate?: any): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    const filters: any = { index, pageSize, sort: 'DESC' };
    if (startDate !== null && startDate !== undefined) {
      filters.startDate = startDate;
    }
    if (endDate !== null && endDate !== undefined) {
      endDate.setHours(23, 59, 59, 999);
      filters.endDate = endDate;
    }
    return this.httpClient.post(`${this.url}/Transfers`, filters, { headers }) // TODO: This can also be used for filtering.
      .pipe(
        map((response: any) => {
          return this.OrderTransactionsHistory(response);
        }),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  getOutgoingTransactions(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    const filters: any = { index: 0, pageSize: 1, sort: 'DESC' };
    filters.operationType = "C";
    filters.eventType = "TRAN";
    return this.httpClient.post(`${this.url}/Transfers`, filters, { headers })
      .pipe(
        map((response: any) => {
          return this.OrderTransactionsHistory(response);
        }),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  getInternalOutgoingTransactions(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    const filters: any = { index: 0, pageSize: 1, sort: 'DESC' };
    filters.operationType = "C";
    filters.eventType = "TRMO";
    return this.httpClient.post(`${this.url}/Transfers`, filters, { headers })
      .pipe(
        map((response: any) => {
          return this.OrderTransactionsHistory(response);
        }),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  sendTransfer(transfer: SendTransfer): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);

    return this.httpClient.post<BuenoWebApiResult>(`${this.url}/Transfers/send`,
      transfer, { headers })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => throwError(this.baseApiService.handleError(error, false))));
  }

  confirmTransfer(confirmTransfer: ConfirmTransfer): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    if (confirmTransfer.code !== undefined) {
      confirmTransfer.code = confirmTransfer.code.trim();
    }
    return this.httpClient.put<BuenoWebApiResult>(`${this.url}/Transfers/confirm`,
      confirmTransfer, { headers })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError(this.baseApiService.handleError(error))));

  }

  getScheduledTransfers(): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.get<BuenoWebApiResult>(`${this.url}/scheduledTransfers`, { headers })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  createScheduledTransfer(transfer: ScheduledTransfer): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);

    return this.httpClient.post<BuenoWebApiResult>(`${this.url}/scheduledTransfers`,
      transfer, { headers })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => throwError(this.baseApiService.handleError(error, false))));
  }

  deleteScheduledTransfer(id: string): Observable<any> {
    const token = this.oAuth.getIdToken();
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    return this.httpClient.delete<BuenoWebApiResult>(`${this.url}/scheduledTransfers?jobId=${id}`, { headers })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError(this.baseApiService.handleError(error))));
  }

  OrderTransactionsHistory(transactionsList: any): any {
    const transfers = [];
    let transactionsLists: TransactionsList[] = [];
    /// append a testitem to the array to transactionsList.transfers to test sepa direct debit
    let addSEPAtestItem = false;
    if (!environment.production && addSEPAtestItem) {
      // get today date as string with format 2023-06-05T08:07:00
      const today = new Date();
      const todayString = today.toISOString();
      const testItem = {
        created: todayString,
        movementId: 'b7949cb4319f94cb58f3ea009689d4247a9c441d1f5edff993ef0c9ea64b6a7b',
        description: 'FACTURA NUM: 23/021874 FECHA 01/06/2023',
        beneficiaryName: 'JOHN ATLE HEGRENES',
        beneficiaryIban: 'ES2767056554650100000050',
        payerIban: 'ES9321007656452200008846',
        payerName: 'DRAGONET COMUNICACIONES S.L UNIPERSONAL',
        amount: -22.45,
        amountNet: -22.45,
        currencyCode: 'EUR',
        actualBalanceAfterMovement: 31.64,
        availableBalanceAfterMovement: 31.64,
        debitOrCredit: 'C',
        commission: 0,
        commissionCondoned: 0,
        origin: 'BAT',
        referenceId: '4958192',
        additionalText: 'DRAGONET COMUNICACIONES S.L UNIPERSONAL',
        type: 'DirectDebitReceived'
      };
      transactionsList.transfers.push(testItem);
    }

    const count = transactionsList.count;
    transactionsList = transactionsList.transfers;

    if (transactionsList !== null) {
      transactionsList.sort((a: { created: string | number | Date; }, b: { created: string | number | Date; }) => {
        /// create a date object here from string movementDate and movementTime.
        // The format of the string is 'aaaammdd' for movementDate and 'hhmmss' for movementTime
        const aDate = new Date(a.created);
        const bDate = new Date(b.created);
        /// now return the difference of the two dates that will be subtracted below
        return bDate.getTime() - aDate.getTime();

      });
      transactionsList.forEach((el: any) => {
        const transaction = el;
        const date = new Date(transaction.created);
        transaction.created_at = date;
        this.addToTransactionsList(date, transaction, transactionsLists); // TODO: Translate pipe
        // TODO: This is a quickfix for situation where hubuc doesn't send sender information for the transfer.
        if (!transaction.sender) {
          transaction.sender = new TransactionSender();
          transaction.sender.name = '';
        }
        transfers.push(transaction);
      });
      if (transactionsLists) {
        transactionsLists = transactionsLists.sort((a, b) => b.dateForOrdering.getTime() - a.dateForOrdering.getTime());
        transactionsLists.forEach(transList => {
          const foundList = transactionsLists.find(x => x.dateForOrdering === transList.dateForOrdering);
          if (foundList) {
            foundList.Transactions = transList.Transactions.sort(
              (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          }
        });
      }
    }
    return { transactionsLists, count };
  }

  addToTransactionsList(date: Date, transaction: Transaction, transactionsLists: TransactionsList[]): void {
    const year = date.getFullYear()
    const month = date.getMonth() + 1;
    const monthString = `MONTHS.${month}`;
    const foundList = transactionsLists.filter(x => x.year === year && x.monthLabel === monthString);
    if (foundList.length > 0) {
      foundList[0].Transactions.push(transaction);
    } else {
      const newList = new TransactionsList();
      newList.year = year;
      newList.monthLabel = monthString;
      newList.dateForOrdering = date;
      newList.Transactions = [transaction];
      transactionsLists.push(newList);
    }
  }
}


